import React, { } from 'react'
import {
    Box,
    InputAdornment,
    IconButton,
    TextField,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    Button,
    Typography,
    Menu,
    ClickAwayListener
} from "@material-ui/core";

import SearchIcon from "@material-ui/icons/Search";
import Modal from '@material-ui/core/Modal';
import NotifictionView from "./NotifictionView";
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
// @ts-ignore
import { ReactComponent as Notifications } from "./Header_Assets/notifications.svg";
// @ts-ignore
import { ReactComponent as ShowMenu } from "./Header_Assets/showMenu.svg";
// @ts-ignore
import { ReactComponent as Profile } from "./Header_Assets/profile.svg";
// @ts-ignore
import { ReactComponent as Settings } from "./Header_Assets/settings.svg";
// @ts-ignore
import { ReactComponent as Signout } from "./Header_Assets/power.svg"
import HeaderController, { Props } from './HeaderController';
import WorkOutlineTwoToneIcon from '@material-ui/icons/WorkOutlineTwoTone';
import PlaceOutlinedIcon from '@material-ui/icons/PlaceOutlined';
import CustomNotification from './CustomNotification';
import Loader from './Loader.web';
import { ProfileDummy, dummyImage } from './assets';

export default class Header extends HeaderController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // const [userEmail, setUserEmail] = useState("");
    // const [userName, setUserName] = useState('');
    // const [userProfile, setUserProfile] = useState("");
    // const [accountType, setAccountType] = useState("");
    // const[open ,setOpen]=useState(false);
    // const handleClose=()=>{
    //     setOpen(false)
    // }

    // const handleOpen=()=>{
    //     setOpen(true);
    // }
    // useEffect(() => {
    //     async function fetchData() {
    //         const currentUserEmail = await getStorageData('email');
    //         setUserEmail(currentUserEmail);
    //         const currentUserName = await getStorageData("full_name")
    //         setUserName(currentUserName);
    //         const currentImage = await getStorageData('image')
    //         setUserProfile(currentImage);
    //         const accountType = await getStorageData('account_type');
    //         setAccountType(accountType);
    //     }
    //     fetchData();
    // }, []);


    render() {
        return (
            <>
                <Loader loading={this.state.isLoading} />
                <header style={webStyle.header}>
                    <Box sx={webStyle.headerAlignments}>
                        <span>
                            {
                                this.props.sideBarStatus ||
                                <MenuOpenIcon style={webStyle.drawerIcon} onClick={this.props.handleSideBar} />
                            }
                        </span>
                        <Box className='fontSizeUrbanist' sx={webStyle.headerSearchBox}>
                            <TextField
                                className='fontSizeUrbanist'
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton style={webStyle.iconButton}>
                                                <SearchIcon style={webStyle.searchIcon} />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                    style: {
                                        borderRadius: "35px",
                                        backgroundColor: "#F8FAFE",
                                        padding: "0px",
                                        height: "44px",
                                        fontFamily: "Urbanist",
                                    } as React.CSSProperties,
                                }}
                                variant="outlined"
                                fullWidth
                                placeholder="Search for vendors..."
                                data-focusvisible-polyfill="false"
                                onChange={this.handleGlobalSearch}
                            />
                            {this.state.searchText &&  !this.state.isLoading ? <Box style={{ zIndex: 1, background: "#ffffff", borderRadius: "12px", width: "100%", boxShadow: "0px 5px 12px rgba(23,24,29,0.07)", position: "absolute", maxHeight: "60vh" }}>
                                <Box style={{ maxHeight: "55vh", overflowX: "hidden", overflowY: "auto", margin: "18px 16px 28px" }}>
                                    {this.state.searchData.length ? <><Typography style={{ height: "17px", color: "#6d89af", paddingBottom: "16px", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 500, letterSpacing: "0.15555556px" }}>Search Results</Typography>
                                        {this.state.searchData.map((data: any, index: number) => {
                                            return <React.Fragment key={`${data.id}_search_data`}>
                                                <Box style={{ display: "flex" }}>
                                                    <img style={{ marginRight: "12px", width: "54px", height: "54px", borderRadius: "12px" }} src={data.attributes.profile_image || "https://www.littlethings.info/wp-content/uploads/2014/04/dummy-image-green-e1398449160839.jpg"} />
                                                    <Box>
                                                        <Typography
                                                            style={{
                                                                lineHeight: "20px",
                                                                color: "#6F7C8E",
                                                                fontFamily: "Urbanist",
                                                                fontSize: "16px",
                                                                fontWeight: 600,
                                                            }}
                                                        >
                                                            <div dangerouslySetInnerHTML={{ __html: data.attributes.full_name.replace(this.getRegex(), (prevText: any) => { return `<span class="boldText">${prevText}</span>` }) }} />                                                        </Typography>
                                                        <Typography style={webStyle.vendorManageProfilePhName}>
                                                            <WorkOutlineTwoToneIcon style={{
                                                                fontSize: "small",
                                                                width: "18px",
                                                                height: "18px",
                                                                marginRight: 4,
                                                            }} />
                                                            {data.attributes.position}
                                                        </Typography>
                                                        <Typography style={webStyle.vendorManageProfilePhName}>
                                                            <PlaceOutlinedIcon style={{
                                                                fontSize: "small",
                                                                width: "18px",
                                                                height: "18px",
                                                                marginRight: 4,
                                                            }} />
                                                            {data.attributes.city}, {data.attributes.state}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                {this.state.searchData.length !== (index + 1) ? <Box style={{ borderTop: "1px solid #e3eaf4", marginTop: "15.5px", marginBottom: "15.5px" }} /> : null}
                                            </React.Fragment>
                                        })}</> :
                                        <Typography style={{ height: "17px", color: "#6d89af", fontFamily: "Urbanist", fontSize: "14px", fontWeight: 500, letterSpacing: "0.15555556px" }}>No records found</Typography>
                                    }
                                </Box>
                            </Box> : null}
                        </Box>
                        <Box style={webStyle.notificationStyle}>
                            <div>
                                <Box style={webStyle.bellIconBackground} onClick={this.handleOpen}>
                                    <Notifications />
                                </Box>
                                <Modal
                                    open={this.state.open}
                                    onClose={this.handleClose} // This handles clicking anywhere outside the modal
                                    aria-describedby="simple-modal-description"
                                    aria-labelledby="simple-modal-title"
                                >
                                    <div style={{ // You can style your modal here
                                        top: '32%',
                                        position: 'absolute',
                                        // Set your desired width
                                        left: '78%',
                                        backgroundColor: 'white',
                                        borderRadius: '20px',
                                        transform: 'translate(-50%, -50%)',
                                        height: '328px',
                                        width: '376px'

                                    } as React.CSSProperties}>
                                        {/* Modal content goes here */}
                                        <NotifictionView data={this.state.notificationdata} />
                                    </div>
                                </Modal>
                            </div>
                            <ClickAwayListener onClickAway={() => {
                                if (this.props.dropDownStatus) {
                                    this.props.handleDropdown()
                                }
                            }}>
                                <Box style={{ position: "relative" }}>
                                    <Box sx={webStyle.headerUserInfo} onClick={this.props.handleDropdown}>
                                        <Box sx={webStyle.userImageBox}>

                                            <img style={webStyle.userImage as React.CSSProperties}
                                                src={this.state.userProfile||dummyImage}
                                            />
                                        </Box>
                                        <span style={webStyle.userName as React.CSSProperties}>
                                            {
                                                this.state.userName}
                                        </span>
                                        <ShowMenu />
                                    </Box>
                                    {
                                        this.props.dropDownStatus && <Box sx={webStyle.dropdown}>
                                            <List>
                                                <ListItem>
                                                    <Box sx={webStyle.userDataInList}>
                                                        <Box sx={webStyle.userImageBoxInList}>
                                                            <img style={webStyle.userImage as React.CSSProperties}
                                                                src={this.state.userProfile||dummyImage}
                                                            />
                                                        </Box>
                                                        <Box>
                                                            <Box sx={webStyle.userNameInList}>
                                                                {this.state.userName}
                                                            </Box>
                                                            <Box sx={webStyle.userEmailInList}>
                                                                {this.state.userEmail}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </ListItem>
                                                <div style={webStyle.seperateDropdown}></div>
                                                <ListItem style={{ cursor: "pointer", gap: "10px" }}
                                                    onClick={this.handleProfileNavigation}
                                                >
                                                    <ListItemAvatar style={webStyle.dropdownIcon}>
                                                        <Profile  style={{width: "18px", height: "18px" }}/>
                                                    </ListItemAvatar>
                                                    <ListItemText primary="Manage Profile" className={`${webStyle.listText} boldText`} />
                                                </ListItem>
                                                <ListItem style={{ cursor: "pointer", gap: "10px" }}
                                                    onClick={this.handleSettingsNavigation}
                                                >
                                                    <ListItemAvatar style={webStyle.dropdownIcon}>
                                                        <Settings />
                                                    </ListItemAvatar>
                                                    <ListItemText primary="Settings" className={`${webStyle.listText} boldText`} />
                                                </ListItem>
                                                <div style={webStyle.seperateDropdown}></div>
                                                <Box style={{ marginTop: '12px', marginLeft: "12px", marginRight: "12px" }}>


                                                    <Button variant="outlined" startIcon={<Signout />} style={webStyle.signout as React.CSSProperties} fullWidth onClick={this.Logoutproject}>
                                                        Sign Out
                                                    </Button>

                                                </Box>
                                            </List>
                                        </Box>
                                    }

                                </Box>
                            </ClickAwayListener>
                        </Box>
                    </Box>
                </header>
                <CustomNotification navigation={(route: string) => this.props.navigate(route)} notification={this.state.notification} handleCloseNotification={this.handleCloseNotification} />
            </>
        )
    }
}



const webStyle = {
    header: {
        borderBottom: "1px solid #E3EAF4",
    },
    vendorManageProfilePhName: {
        color: "#6d89af",
        lineHeight: "15px",
        fontSize: "12px",
        fontFamily: "Urbanist",
        letterSpacing: "0.2px",
        marginTop: "2px",
        fontWeight: 500,
        display: "flex",
        alignItems: "center",
    },
    headerAlignments: {
        /// position: "fixed",
        //top: 0,
        //left: 0,
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "18px",
        paddingBottom: '18.95px',
        "@media screen and (max-width: 500px)": {
            flexDirection: "column",
            alignItems: 'flex-start',
            gap: '12px',
            height: "10rem"
        },
    },
    headerUserInfo: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        border: "1px solid #E3EAF4",
        backgroundColor: "#F8FAFE",
        padding: "6px",
        borderRadius: "30px",
        cursor: "pointer",
        position: "relative",
    },
    headerSearchBox: {
        position: "relative",
        width: "40%",
        "@media screen and (max-width: 500px)": {
            width: "100%",
        },
        '& .MuiInputBase-input': {
            fontFamily: "Urbanist",
            fontSize: '14px',
            lineHeight: '17px',
            fontWeight: 500,
            color: '#6D89AF',
            letterSpacing: '0.25454545px',
        },
        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            border: '1px solid rgba(0, 0, 0, 0.23)',
        },
        '& :focus:not([data-focusvisible-polyfill])': {
            border: 'none',
        }
    },
    // inputFieldtext:{
    // 
    typographyDisplayBlock: {
        display: 'block',
        color: 'red',
        fontWeight: 'bold',
    },
    iconButton: {
        margin: "0px",
        padding: "0px",
    },
    searchIcon: {
        color: "#6D89AF",
        fontSize: "22px",
        marginLeft: 14,
    },
    notificationStyle: {
        display: "flex",
        justifyContent: "center",
        //alignItems: "center",
        gap: "18px",
        paddingRight: "24px",
    },
    userName: {
        margin: "0 9px 0 5px",
        fontWeight: "600"
    },
    bellIconBackground: {
        width: "44px",
        height: "44px",
        backgroundColor: "#F8FAFE",
        border: "1px solid #E3EAF4",
        borderRadius: "22px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        marginLeft: "10px"
    },
    userImageBox: {
        width: "32px",
        height: "32px",
        borderRadius: "15px",
        overflow: "hidden",
        marginRight: "10px",
        position: "relative"
    },
    userImage: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        position: "absolute",
        top: "0",
        left: "0",
    },
    dropdown: {
        display: "inline-block",
        position: "absolute",
        bottom: "-210px",
        right: "10px",
        backgroundColor: "#fff",
        width: "230px",
        borderRadius: "10px",
        zIndex: "10",
        border: "0.5px solid #E3EAF4",
        boxShadow: "0px 5px 12px rgba(23, 24, 29, 0.07)",
        '& .MuiTypography-displayBlock': {
            fontWeight: 600
        },
    },
    signout: {
        textTransform: "none",
        borderRadius: "20.5px",
        backgroundColor: "#F8FAFE",
        color: "#6D89AF",
        paddingTop: "6px",
        paddingBottom: "6px",
        borderColor: "#E3EAF4",
        paddingRight: "1px"
    },
    seperateDropdown: {
        borderBottom: "1px solid #E3EAF4",
        width: "80%",
        margin: "0 auto"
    },
    dropdownIcon: {
        minWidth: "0",
        marginRight: "10px"
    },
    listText: {
        margin: "0",
        padding: "0",
        fontSize: "14px",
        lineHeight: "17px",
        fontWeight: 500,
        color: "#17181D",
    },
    userDataInList: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        position: "relative",
    },
    userEmailInList: {
        color: "#6D89AF",
        fontSize: "12px",
        marginTop: '3px',
    },
    userImageBoxInList: {
        width: "30px",
        height: "30px",
        borderRadius: "6px",
        overflow: "hidden",
        marginRight: "5px",
        position: "relative",
    },
    userNameInList: {
        fontWeight: "600"
    },
    drawerIcon: {
        color: "#fff",
        backgroundColor: "black",
        borderRadius: "14px",
        padding: "10px",
        cursor: "pointer",
        fontSize: '24px'
    }
}